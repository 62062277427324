.axis {
  clip-path: url(#clip);
}

.axis.axis--x5 .tick line {
  stroke: #ffffff;
}

.axis.axis--x4 .tick text {
  color: #939598;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.58px;
}

.dots {
  clip-path: url(#clip);
}

.focus-area {
  fill: #f1f1f2;
  /* fill-opacity: 0.5 */
}

.axis path {
  stroke-width: 6px;
}

.handle-line {
  stroke-width: 6px;
}

.axis .tick text {
  font-size: 12px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.brush rect.selection {
  fill: none;
  stroke: none;
}
