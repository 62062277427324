@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700,800');

.hidden {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  background: #f1f2f2;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-display: block;
}

.root {
  flex: 1;
  /* max-width: 1000px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  margin: auto;
  position: relative;
}

.header-wrapper {
  background: #202a2b;
  /* background: #2D3738; */
  position: fixed;
  top: 0;
  left: 0;
}

.header {
  background: #202a2b;
  position: absolute;
  top: 0;
}

.header-text {
  color: #FFFFFF;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-display: optional;
  font-size: 22px;
  font-weight: bold;
  line-height: 64px;
  padding-left: 15px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.header-filters {
  position: absolute;
  top: 0px;
  right: 150px;
  line-height: 64px;
  display: inline-block;
}

.header-filter-button {
  margin-left: 10px;
  cursor: pointer;
  color: #4EB8C1 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}

.header-filter:focus {
  outline: 0;
}

.header-filter-icon {
  position: relative;
  top: 0px;
  font-size: 18px;
  width: 19px;
}

.white-text {
  color: #ffffff !important;
}

.header-icons {
  position: absolute;
  top: 0px;
  right: 24px;
  color: #98B9BD;;
  display: inline-block;
  font-size: 18px;
  line-height: 64px;
}

.header-icon {
  margin-left: 15px;
  cursor: pointer;
}


.home-background {
  position: fixed;
  background: #F1F2F2;
}

.home-header-button-link {
  color: #4EB8C1;
  text-decoration: none;
}

.home-header-button-outline {
  border-color: #4EB8C1 !important;
  border-radius: 20px !important;
}

.download-button,
.download-button-outline .MuiButton-startIcon {
  color: #FFFFFF;
  text-decoration: none;
}

.download-button-outline {
  border-radius: 20px !important;
  background: #4EB8C1 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.home-container {
  position: absolute;
  overflow-y: auto;
}

.home-image {
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  background-image: url("../../public/images/main.jpg");
  /* background: lightgray; */
}

.home-image-text {
  padding-left: 43px;
  padding-top: 80px;
  /* max-width: 380px; */
}

.home-image-text-welcome {
  text-transform: uppercase;
  height: 24px;
  color: #202A2B;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 24px;
}

.home-image-text-set {
  padding-top: 5px;
  height: 104px;
  color: #202A2B;
  font-family: "Roboto Condensed";
  font-size: 48px;
  font-weight: bold;
  line-height: 52px;
  max-width: 520px;
}

.home-image-text-desc {
  padding-top: 24px;
  height: 112px;
  color: #202A2B;
  font-family: "Roboto Condensed";
  /* font-family: "Crimson Text"; */
  /* font-family: "Minion Pro", "Crimson Text"; */
  font-size: 20px;
  line-height: 28px;
  max-width: 380px;
}

.home-buildvis {
  width: 100%;
  background: #fff;
}

.home-buildvis-inner {
  height: 120px;
  display: flex;
  align-items: center;
}

.home-buildvis-text {
  font-family: "Roboto";
  /* font-family: "DIN OT", "Barlow"; */
  /* font-weight: bold; */
  /* font-family: "Barlow"; */
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  padding-left: 43px;
  padding-right: 10px;
}

.home-buildvis-link {
  color: #202A2B;
  text-decoration: none;
  cursor: pointer;
}

.home-buildvis-button {
  margin-left: 15px !important;
  height: 48px;
  width: 48px;
}

.home-buildvis-button-text {
  color: #FFFFFF !important;
  line-height: 18px;
  /* padding-top: 5px !important; */
}

/* button: 33x33 */

.home-instructions {
  width: 100%;
}

.home-offrow {
  color: #F1F2F2;
}

.home-instructions-image {
  height: 320px;
  background-color:#CCCCCC;
  margin-left: 43px;
  margin-right: 43px;
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 414px;
}

.home-instructions-image1 {
  background-image: url("../../public/images/step1.png");
  background-size: cover;
  background-position: left;
}

.home-instructions-image2 {
  background-image: url("../../public/images/step1.png");
  background-size: cover;
  background-position: right;
}

.home-instructions-image3 {
  background-image: url("../../public/images/step3.png");
  background-size: cover;
}

.home-instructions-image4 {
  background-image: url("../../public/images/step4.png");
  background-size: cover;
}

.home-instructions-desc {
  height: 320px;
  margin-left: 43px;
  margin-right: 43px;
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 414px;
}

.home-instructions-number {
  width: 34px;
  height: 34px;
  border: 1px solid #4EB8C1;
  border-radius: 18px;
  color: #4EB8C1;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 10px;
}

.home-instructions-header {
  text-transform: uppercase;
  color: #202A2B;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 24px;
  margin-bottom: 8px;
}

.home-instructions-text {
  color: #202A2B;
  /* font-family: "Crimson Text"; */
  /* font-family: "Minion Pro", "Crimson Text"; */
  font-family: "Roboto Condensed";
  font-size: 20px;
  line-height: 28px;
}

.home-carousel-container {
  height: 600px;
  background-color: #202A2B;
  position: relative;
}

.home-carousel-header {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 24px;
  text-transform: uppercase;
  /* padding-left: 120px; */
  padding-top: 75px;
}

.home-carousel-box {
  height: 410px;
  padding-top: 22px;
  overflow-x: hidden;
  position: relative;
}

.home-carousel-button {
  height: 48px;
  width: 48px;
  color: #FFFFFF !important;
}

.home-carousel-button-root {
  background-color: #4EB8C1 !important;
}

.home-carousel-button-left {
  position: absolute;
  top: 190px;
  /* left: 40px; */
}

.home-carousel-button-right {
  position: absolute;
  top: 190px;
  right: 40px;
}

.home-carousel-item {
  height: 416px;
  width: 770px;
  background-color: #F1F2F2;
  position: absolute;
  top: 10px;
}

.home-carousel-image-container {
  position: absolute;
  left: 0;
  top: 0;
}

.home-carousel-img {
  height: 416px;
}

.home-carousel-text-container {
  position: absolute;
  left: 310px;
  top: 0;
}

.home-carousel-item-title {
  color: #202A2B;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 24px;
  text-transform: uppercase;
  padding-top: 74px;
  padding-left: 80px;
}

.home-carousel-item-desc {
  color: #202A2B;
  font-family: "Roboto Condensed";
  font-size: 20px;
  line-height: 28px;
  width: 300px;
  padding-left: 80px;
  /* font-weight: 300; */
  padding-top: 5px;
}

.carousel-button {
  position: absolute;
  bottom: 64px;
  left: 310px;
  padding-top: 20px;
  padding-left: 80px;
}

.carousel-button-root {
  background-color: #4EB8C1 !important;
  border-radius: 20px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.carousel-button-label {
  color: #FFFFFF;
  text-decoration: none;
}

.home-download {
  height: 120px;
	background-color: #4EB8C1;
  display: flex;
  align-items: center;
}

.home-download-text {
  color: #FFFFFF;
  font-family: "Roboto";
  /* font-family: "DIN OT", "Barlow"; */
  /* font-weight: bold; */
  /* font-family: "Barlow"; */
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  padding-left: 43px;
  padding-right: 5px;
}

.home-download-text-link {
  color: #FFFFFF;
  text-decoration: none;
}

.home-download-button-text {
  color: #FFFFFF;
  line-height: 18px;
  /* padding-top: 5px; */
}

.home-download-button {
  height: 48px;
  width: 48px;
  color: #FFFFFF !important;
}

.home-methodology {
  height: 120px;
	background-color: #FFFFFF;
  display: flex;
  align-items: center;
}

.home-methodology-text {
  color: #202A2B;
  font-family: "Roboto";
  /* font-family: "DIN OT", "Barlow"; */
  /* font-weight: bold; */
  /* font-family: "Barlow"; */
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  padding-left: 43px;
  padding-right: 5px;
}

.home-methodology-text-link {
  color: #202A2B;
  text-decoration: none;
}

.home-methodology-button {
  margin-left: 15px !important;
  height: 48px;
  width: 48px;
  background-color: #4EB8C1 !important;
}

.home-methodology-button-text {
 color: #FFFFFF !important;
 /* padding-top: 5px; */
 line-height: 18px;
}

.home-info-header {
  padding-top: 75px;
  padding-left: 15px;
  color: #202A2B;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 24px;
  text-transform: uppercase;
}

.home-info {
  padding-left: 32px;
  padding-right: 32px;
  background: #F1F2F2;
  padding-bottom: 40px;
}

.home-info-column {
  padding-left: 10px;
  padding-right: 10px;
  color: #3D5052;
  font-family: Roboto;
  font-size: 16px;
  line-height: 23px;
}

.home-info-logo {
  height: 100px;
  border-bottom: 2px solid #939598;
  border-bottom-style: dotted;
  padding-top: 20px;
}

.home-learn-more {
  color: #4EB8C1;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 25px;
  text-transform: uppercase;
  text-decoration: none;
}

.methodology-container {
  padding-top: 116px;
}

.methodology-inner {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}

.methodology-title {
  color: #202A2B;
  font-family: "Roboto Condensed";
  font-size: 52px;
  font-weight: bold;
  line-height: 52px;
}

.methodology-subtitle {
  height: 22px;
	color: #939598;
	font-family: Roboto;
	font-size: 14px;
	font-style: italic;
  line-height: 22px;
}

.methodology-header {
	height: 33px;
	color: #202A2B;
	font-family: "Roboto Condensed";
	font-size: 18px;
	font-weight: bold;
  line-height: 25px;
  width: 100%;
  padding-bottom: 3px;
  border-bottom: 2px dotted #939598;
  margin-bottom: 12px;
  margin-top: 40px;
}

.methodology-text {
	color: #3D5052;
	font-family: "Roboto";
	font-size: 18px;
	line-height: 26px;
	/* font-family: "Minion Pro";
	font-size: 20px;
	line-height: 28px; */
}

.methodology-list {
  list-style-type: none;
}

.methodology-list > li:before {
  content: '\2014'; /* em dash */
  position: absolute;
  margin-left: -1.1em;
}

.methodology-download-button {
  padding-top: 20px;
  padding-bottom: 100px;
}

.notfound-container {
  padding-top: 116px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.notfound-header {
	height: 52px;
	color: #202A2B;
	font-family: "Roboto Condensed";
	font-size: 52px;
	font-weight: bold;
  line-height: 52px;
  padding-bottom: 55px;
}

.notfound-text {
  height: 33px;
  color: #202A2B;
  font-family: "Roboto Condensed";
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  border-bottom: 2px dotted #939598;
  margin-bottom: 15px;
}

.notfound-text2 {
	color: #3D5052;
	font-family: "Roboto Condensed";
	font-size: 20px;
	line-height: 28px;
}

.notfound-button {
  padding-top: 10px;
}

.notfound-button-root {
  background-color: #4EB8C1 !important;
  border-radius: 20px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.notfound-button-label {
  color: #FFFFFF;
  text-decoration: none;
}

.contact-header-shadow {
  position: fixed;
  top: 0px;
  height: 64px;
  width: 100%;
	/* background-color: #202A2B; */
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
}

.contact-outer {
  background-color: #202A2B;
}

.contact-container {
  padding-top: 116px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.contact-title {
	height: 52px;
	color: #FFFFFF;
	font-family: "Roboto Condensed";
	font-size: 52px;
	font-weight: bold;
  line-height: 52px;
  padding-bottom: 24px;
  border-bottom: 2px dotted #939598;
  position: relative;
}

.contact-content {
  padding-top: 24px;
}

.contact-entry {
  padding-bottom: 51px;
}

.contact-entry-title {
  height: 28px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.contact-button {
  height: 33px;
  width: 33px;
  color: #FFFFFF !important;
}

.contact-button-root {
  padding: 5px !important;
  background-color: #4EB8C1 !important;
}

.content-entry-subtitle {
  padding-top: 2px;
  height: 20px;
  color: #6B6B6D;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
}

.contact-back {
	height: 24px;
	color: #4EB8C1;
	font-family: Roboto;
	font-size: 14px;
	font-weight: bold;
  line-height: 24px;
  position: absolute;
  left: -67px;
  top: 0px;
  cursor: pointer;
}

.contact-chevron {
  font-size: 14px !important;
  margin-bottom: -2px;
}

.contact-button-root {
  background-color: #4EB8C1 !important;
  border-radius: 20px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.contact-button-root:disabled {
  background-color: #aaaaaa !important;
}

.contact-button-root:disabled > .contact-button-label {
  color: #cccccc !important;
}

.contact-button-label {
  color: #FFFFFF;
}

.contact-detail-header {
	height: 28px;
	color: #FFFFFF;
	font-family: Roboto;
	font-size: 24px;
	font-weight: bold;
  line-height: 28px;
  padding-bottom: 18px;
}

.contact-detail-label {
	height: 20px;
	color: #FFFFFF;
	font-family: Roboto;
	font-size: 14px;
  line-height: 20px;
  padding-top: 27px;
  padding-bottom: 9px;
}

.contact-detail-select-outline {
	width: 297px !important;
	border: 1px solid #6B6B6D !important;
  background-color: rgba(255,255,255,0.1) !important;
  border-radius: 4px !important;
}

.contact-detail-select {
  color: #FFFFFF !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  line-height: 32px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 4px !important;
  border: none !important;
}

.contact-detail-textfield-root {
  margin-top: 10px;
  width: 460px;
  padding: 12px !important;
}

.contact-detail-textfield-root2 {
  width: 300px;
}

.contact-detail-textfield-text {
  color: #FFFFFF !important;
  /* color: #6B6B6D !important; */
  font-family: Roboto !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.contact-detail-textfield {
  border-radius: 0px !important;
  border: 1px solid #939598 !important;
}

.contact-detail-spacer {
  height: 10px;
}

.contact-detail-spacer2 {
  height: 60px;
}

.footer {
  border-top: 1px solid #939598;
  margin-left: 42px;
  margin-right: 42px;
  height: 50px;
  color: #8A95A1;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  padding-top: 5px;
}

.footer-text {
  padding-left: 25px;
}

.footer a {
  color: #8A95A1;
  text-decoration: none;
}

.link-text {
  word-wrap: break-word;
}

.agerange-container {
  top: 0px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #3D5052;
  background-color: #202A2B;
  box-sizing: border-box;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #FFFFFF;
}

.agerange-header {
  color: #FFFFFF;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding-bottom: 12px;
}

.agerange-text {
  padding-bottom: 12px;
  color: #cccccc;
  font-size: 14px;
}

.agerange-select {
  height: 48px;
  width: 280px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif !important;
  color: #ffffff !important;
}

.agerange-select path {
  color: #ffffff !important;
  fill: #fff !important;
}

.agerange-select fieldset {
  border: 1px solid #939598 !important;
}

/* :focus {
  outline-color: red !important;
} */

.filters-container {
  top: 0px;
  position: fixed;
  border: 1px solid #3D5052;
  background-color: #202A2B;
  box-sizing: border-box;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #FFFFFF;
}

.filters-header {
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.9px;
}

.filters-inner {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.filter-column {
  width: 33%;
  position: relative;
  height: 460px;
  margin-left: 40px;
  box-sizing: border-box;
  border-right: 1px solid #6B6B6D;
}

.filter-column:last-child {
  border-right: none;
}

.filter-column-header {
  width: 100%;
  height: 60px;
  display: flex;
  position: absolute;
  right: 20px;
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #6B6B6D;
}

.filter-column-header span {
  align-self: flex-end;
}

.filter-column-content {
  position: absolute;
  top: 80px;
  margin-left: -20px;
}

/* this is for the last column that needs to be higher */
.filter-column-content2 {
  top: 65px;
}

.filter-column-orfi-text {
  color: #cccccc;
  font-size: 14px;
}

.orfi-add {
  border-bottom: 1px solid #6B6B6D;
  margin-bottom: 10px;
  padding-bottom: 5px;
  margin-right: 20px;
}

.filter-button {
  height: 30px;
  border-radius: 20px;
  cursor: pointer;
  display: table;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 30px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.filter-button.unselected {
  border: 1px solid #6B6B6D;
  background-color: #202A2B;
  color: #CCCCCC;
}

.filter-button.selected {
  background-color: #4EB8C1;
  color: #FFFFFF;
  border: 1px solid #4EB8C1;
}

.filter-button:focus {
  outline: 0;
}

.filter-button:hover {
  background-color: #13191A;
}

.filter-button.selected:hover {
  background-color: #359FA8;
}

.pathway-container {
  /* top: 70px;
  right: 20px;
  position: absolute; */
  /* border: 1px solid #3D5052; */
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24); */
  color: #FFFFFF;
}
.pathways-container {
  box-sizing: border-box;
  padding-bottom: 10px;
  max-height: 336px;
  overflow-y: auto;
}

.pathway-header {
  /* padding-left: 20px; */
  /* padding-top: 10px; */
}

.pathway-section-header {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding-top: 8px;
}

.pathway-container {

}

.pathway-empty-text {
  font-size: 12px;
  color: #939598;
  line-height: 18px;
  margin-right: 20px;
}

.pathway-multi-select {
  width: 300px;
  display: inline-block;
}

.pathway-chip {
  border: 1px solid #6B6B6D !important;
  background-color: #202A2B !important;
  color: #ffffff !important;
  margin: 2px;
}

.pathway-chip span {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pathway-chip svg path {
  fill: #6F7F8F !important;
}




.review-refs-container {
  top: 0px;
  display: flex;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #3D5052;
  background-color: #202A2B;
  box-sizing: border-box;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  /* padding-left: 23px;
  padding-right: 23px;
  padding-top: 10px;
  padding-bottom: 10px; */
  color: #FFFFFF !important;
}

.review-refs-container a {
  color: #000000 !important;
}







.slider-container {
  position: absolute;
  left: 0px;
  /* background: #2D3738; */
  background: #202a2b;
}

.actions-header {
  position: absolute;
  color: #4EB8C1;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: right;
  text-transform: uppercase;
}

.action-item {
  padding-left: 20px;
  cursor: pointer;
}

.action-item:focus {
  outline: 0;
}


.pinned-container {
  background: rgba(255, 255, 255, 0.7);
  padding-top: 10px;
  padding-bottom: 5px;
  position: relative;
}

.pinned-header {
  position: relative;
  padding-left: 15px;
  padding-bottom: 5px;
  color: #202A2B;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
}

.pinned-icon {
  color: #98b9bd;
  padding-left: 6px;
  position: relative;
  top: 4px;
}

.pinned-button {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 5px;
  height: 32px;
  width: 140px;
  border-radius: 20px;
  background-color: #FFFFFF;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  color: #4EB8C1;
  font-size: 14px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
}

.pinned-instructions {
  padding-left: 15px;
  color: #939598;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: italic;
}

.cover1 {
  position: fixed;
  background: #fff;
  opacity: 0.4;
  pointer-events: none;
}

.cover2 {
  position: fixed;
  background: #fff;
  opacity: 0.05;
  pointer-events: none;
}

.events-container {
  position: absolute;
  left: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.events-empty {
  padding-top: 20px;
  font-style: italic;
  color: #939598;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.eventgroup-hline {
  stroke: #51b8c0;
  stroke-width: 2px;
  stroke-linecap: round;
}

.eventgroup-header {
  /* margin-top: 25px; */
  /* border-top: 2px dashed #51b8c0; */
  /* padding-top: 4px; */
  padding-left: 15px;
  position: relative;
  padding-bottom: 15px;
}

.eventgroup-header-text {
  display: inline-block;
}

.eventgroup-header-info {
  color: #939598;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: italic;
  vertical-align: top;
}

.eventgroup-header-icons {
  position: absolute;
  right: 30px;
  display: inline-block;
  color: #70B6BC;
  font-size: 22px;
}

.eventgroup-header-icon {
  margin-left: 20px;
  cursor: pointer;
}

.header-search-box {
  position: fixed;
  top: 8px;
  z-index: 99999;
  color: #ffffff;
}

.eventgroup-cat {
  padding-left: 10px;
  color: #939598;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 14px;
}


.eventgroup-subcat {
  color: #202A2B;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
}


/* events elements */

.event-row {
  height: 30px;
  /* font-family: 'Times New Roman'; */
  /* font-weight: 300; */
  /* opacity: 0.8; */
}

.event-row-abs {
  font-size: 14px;
  position: relative;
}

.event {
  /* display: inline-block; */
  position: absolute;
  top: 0px;
  height: 26px;
  border-radius: 12px;
  margin-bottom: 5px;
  line-height: 26px;
  white-space: nowrap;
  cursor: pointer;
  /* this transition is when they become active */
  /* height first, then text */
  /* transition: height 0.15s ease-in-out 0.25s, opacity 0.1s ease-in 0.25s; */
  /* transition: all 0.15s ease-in; */
}

.event-nonpeak {
  top: 11px;
  height: 4px;
  position: absolute;
  background-color: white;
  border-bottom: 1px solid lightgray;
}

.event-peak {
  height: 26px;
  /* margin-left: 14px; */
  border-radius: 14px;
  position: relative;
  background-color: white;
  border-bottom: 0.5px solid lightgray;
}

.event-peak-st {
  position: absolute;
  top: 0px;
  left: -16px;
}

.event-peak-nd {
  position: absolute;
  top: 0px;
}

.event-peak-tl {
  opacity: 0.5;
  position: absolute;
  left: -6px;
  top: 3px;
  width: 11px;
  height: 9px;
  border-bottom-right-radius: 33px 33px;
}

.event-peak-shadow {
  filter: drop-shadow( 0px 1px 0px lightgray);
}

/* .event-peak:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 5px;
  width: 23px;
  height: 16px;
  background:radial-gradient(ellipse at top left,transparent 12px, white 0) top left,
    radial-gradient(ellipse at bottom left,transparent 12px, white 0) bottom left;
  background-size:50% 50%;
  background-repeat:no-repeat;
}

.event-peak:after {
  content: "";
  position: absolute;
  right: -10px;
  top: 5px;
  width: 23px;
  height: 16px;
  background:radial-gradient(ellipse at top right,transparent 12px, white 0) top right,
    radial-gradient(ellipse at bottom right,transparent 12px, white 0) bottom right;
  background-size:50% 50%;
  background-repeat:no-repeat;
} */

.event-text {
  position: absolute;
  top: 0px;
  left: 8px;
  color: #6B6B6D;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.event-pinned-class-text {
  font-style: italic;
  padding-left: 5px;
  font-size: 15px;
}

.event-hoverinfo {
  position: absolute;
  top: 0px;
  height: 26px;
  line-height: 26px;
  background: rgba(255, 255, 255, 0.65);
  border: 1px solid rgba(200, 200, 200, 0.3);
  box-sizing: border-box;
  z-index: 1000;
}

.hoverinfo-text {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  color: #4EB8C1;
  font-weight: 400;
}

/* .event2 {
  height: 3px;
  background: white;
  position: relative;
  top: 9px;
  margin-top: -3px;
} */

.expand-info {
  background: #202A2B;
  color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}

.expand-info-wrapper {
  background: #2D3738;
}

.expand-info-header {
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.28px;
}

.expand-info-age-container {
  width: 90px;
  display: inline-block;
  padding-left: 15px;
  font-size: 12px;
}
.expand-info-age-label {
  color: #F1F2F2;
  font-family: Roboto;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.expand-info-age {
  color: #FFFFFF;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.expand-info-hline {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
  height: 1px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.expand-info-detail {
  padding-left: 15px;
  padding-right: 15px;
  color: #CCCCCC;
  font-family: Roboto;
  font-size: 13px;
  line-height: 18px;
}

.expand-info-expand-refs {
  color: #4EB8C1;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 25px;
  text-align: right;
  padding-right: 10px;
}

.expand-info-ref-link {
  cursor: pointer;
}

.expand-info-ref-button {
  font-size: 18px;
  position: relative;
  top: 3px;
  cursor: pointer;
}

.expand-info-ref-content {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.refs-entry {
  display: inline-block;
  padding-left: 5px;
  text-indent: -5px;
  color: #FFFFFF;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
}

.refs-entry a {
  color: #FFFFFF;
  text-decoration: none;
}

/* end events elements */



/* select */

.react-autosuggest__container {
  position: relative;
  display: inline-block;
  /* margin-left: 20px; */
  /* margin-bottom: 10px; */
}

.react-autosuggest__input {
  height: 30px;
  padding-left: 15px;
  font-weight: 300;
  font-size: 16px;
  height: 48px;
  width: 300px;
  box-sizing: border-box;
  color: #ABABAB;
  font-family: "Roboto Condensed";
  font-size: 16px;
  line-height: 24px;
  background: #202A2B;
  border: 1px solid #939598;
}

.react-autosuggest__input:focus {
  background-color: rgba(255,255,255,0.1);
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  border: 1px solid #6B6B6D;
  background-color: rgba(32, 42, 43, 0.9);
  /* background-color: #202A2B; */
  font-family: "Roboto Condensed";
  font-size: 16px;
  line-height: 32px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 3px 6px 0 rgba(0,0,0,0.24);
  box-sizing: border-box;
  font-weight: 300;
  font-size: 16px;
  z-index: 2;
  max-height: 450px;
  overflow-y: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion-match {
  color: white;
  font-weight: bold;
}

.suggestion-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.suggestion-container--highlighted {
  background-color: #6B6B6D;
}

.suggestion-text {
  flex: 1 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

/*
container: 320
lr padding: 20
ho: 91
rf: 64
int: 68
*/

.suggestion-class {
  flex: 0 auto;
  padding-left: 5px;
  color: #939598;
  font-family: Roboto;
  font-size: 12px;
  white-space: nowrap;
}
